import { create } from "zustand";
import JobHai from "../api/JobHai";

// Enum for state management
const TemplateStates = {
  PENDING: "PENDING",
  IDLE: "IDLE",
  FETCHED: "FETCHED",
  ERROR: "ERROR"
};

const useWhatsAppTemplateStore = create(set => ({
  // State variables
  templateStatus: TemplateStates.IDLE,
  whatsappTemplate: {},
  errorMessage: null,
  // Fetch WhatsApp template details
  fetchWhatsAppTemplate: async ({ leadId, bucketId }) => {
    if (!leadId) {
      set({ templateStatus: TemplateStates.ERROR });
      return;
    }

    set({ templateStatus: TemplateStates.PENDING });

    try {
      const response = await JobHai.get(
        `/lms/whatsapp-template?leadId=${leadId}`
      );
      const templateData = response.data.data;

      // Update lead status after fetching template
      await useWhatsAppTemplateStore
        .getState()
        .updateLeadStatus({ leadId, bucketId });

      set({
        whatsappTemplate: templateData,
        templateStatus: TemplateStates.FETCHED,
        errorMessage: null
      });
    } catch (error) {
      set({
        whatsappTemplate: {},
        errorMessage: error.message,
        templateStatus: TemplateStates.ERROR
      });
    }
  },

  // Update lead status after WhatsApp interaction
  updateLeadStatus: async ({ leadId, bucketId }) => {
    try {
      const payload = { leadId, bucketId, whatsappConnected: 1 };
      const response = await JobHai.post("/lms/call-details", payload);
      return response.data.data;
    } catch (error) {
      console.error("Error updating lead status:", error);
      throw error;
    }
  }
}));

export default useWhatsAppTemplateStore;
