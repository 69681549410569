import { useEffect } from "react";
import { customUseEffect } from "../../../services/utils";
import useLeadRecruiterDetailStore from "../../../zustandService/useLeadRecruiterDetailStore";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbLink,
  BreadcrumbItem
} from "../../ui/breadcrumb";
import CreditWidget from "../CreditWidget";
import BasicDetails from "./basicDetails";
import CallStatus from "./callStatus";
import CompanyDetails from "./companyDetails";
import LeadDetails from "./leadDetail";
import RecentSales from "./recentSales";
import RecruiterDetailsUI from "./recruiterDetailsUi";
import LeadsHistory from "./leadHistory";

export default function RecruiterDetails() {
  const {
    fetchRecruiterDetails,
    fetchRecentSales,
    fetchCallDetails,
    fetchLeadDetails,
    recDetails,
    setSalesData
  } = useLeadRecruiterDetailStore(state => ({
    fetchRecruiterDetails: state.fetchRecruiterDetails,
    fetchRecentSales: state.fetchRecentSales,
    fetchCallDetails: state.fetchCallDetails,
    fetchLeadDetails: state.fetchLeadDetails,
    recDetails: state.recruiterDetails,
    setSalesData: state.setSalesData
  }));
  const searchParams = window.location.search || "";
  const queryParams = new URLSearchParams(searchParams);
  const recId = queryParams.get("recruiterId");
  const bucketId = queryParams.get("bucketId");
  const leadId = queryParams.get("leadId");
  useEffect(() => {
    return () => {
      setSalesData(null, "salesSelectedTab");
      setSalesData({}, "recentSales");
    };
  }, []);
  customUseEffect(() => {
    fetchRecruiterDetails({ recruiterId: recId });
    fetchCallDetails({ leadId: leadId, recId: recId });
    fetchLeadDetails({ leadId, bucketId });
  }, []);

  return (
    <div className="md:grid  grid-cols-6 w-screen lg:w-[calc(100vw-15rem)] h-screen lg:overflow-hidden ">
      <div className="p-6 col-span-4  space-y-6 overflow-scroll border-r">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/lfms">Back</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>
                {recDetails?.recruiterId
                  ? `${recDetails?.recruiterName} (${recDetails?.recruiterId})`
                  : ""}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <BasicDetails />
        <LeadDetails />
        <CompanyDetails />
        <RecruiterDetailsUI />
        <RecentSales />
        <LeadsHistory
          orgId={recDetails?.orgId}
          recId={recDetails?.recruiterId}
        />
      </div>
      <div className="p-6 pt-6 col-span-2 space-y-6 overflow-scroll">
        <CallStatus />
        <CreditWidget recId={recId} />
      </div>
    </div>
  );
}
