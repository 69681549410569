import React from "react";
import { UnverifiedBucketList } from "../../../constants/MetaData";

const RecBucket = ({ pathname, sectionData }) => {
  return (
    <div className="row rec-bucket rec-bucket-center">
      {UnverifiedBucketList.map(item => {
        const data = sectionData[item.value] || {}; // Fallback to an empty object
        if (!sectionData[item.value] && item.value === "ivr_wip") return null;
        return (
          <div className="box relative" key={`bucket-${item.id}`}>
            <a
              href={`${pathname}?bucket=${item.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.hasPremiumJob ? (
                <div
                  className="absolute top-2 right-1 w-2 h-2 bg-red-500 rounded-full z-10"
                  title="Has Premium Jobs"
                />
              ) : null}
              {item.label} ({data.count || 0})
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default RecBucket;
