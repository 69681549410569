import { customDateFormat } from "../../../services/utilsV2";
import useMetadataStore from "../../../zustandService/useMetadataStore";
import { useEffect, useState } from "react";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider
} from "@jobhai-component-library/jh-tooltip";
import { Text } from "@jobhai-component-library/jh-text";
import { Info } from "lucide-react";
import {
  JhTable,
  JhTableBody,
  JhTableCell,
  JhTableHead,
  JhTableRow,
  JhTableHeader
} from "../../../Tailwind/design-packages/tablePackage";
import { dispositionIdMap } from "../../../constants/MetaData";
import { JhText } from "../../../Tailwind/design-packages/textPackage";

const LeadHistoryList = ({ leadsHistory = [], selectedTab = "" }) => {
  let defaultHeaders = [
    { text: "Activty Date", key: "activityDate" },
    { text: "Activity Type", key: "activityType" },
    { text: "Source", key: "source" },
    { text: "Moderator", key: "moderator" },
    { text: "Expiry Date", key: "expiryDate" },
    { text: "Follow Up Date", key: "followUpDate" },
    { text: "Disposition", key: "disposition" },
    { text: "Bucket", key: "Bucket" },
    { text: "Remarks", key: "remarks" }
  ];
  let headers = defaultHeaders;
  if (selectedTab === "organisation") {
    headers = [
      ...defaultHeaders.slice(0, 1),
      { text: "Recruiter", key: "recruiterId" },
      ...defaultHeaders.slice(1)
    ];
  }
  const { insideSalesBucketObject, insideSalesLeadSourceObject } =
    useMetadataStore(state => ({
      insideSalesBucketObject: state.insideSalesBucketObject,
      insideSalesLeadSourceObject: state.insideSalesLeadSourceObject
    }));

  const formatToTitleCase = string => {
    return string
      .split("_")
      .map(word => word.slice(0, 1) + word.slice(1).toLowerCase())
      .join(" ");
  };

  const renderCellContent = (item, headerKey) => {
    switch (headerKey) {
      case "activityDate":
        if (item.createdAt === null) return "-";
        return customDateFormat(item.createdAt);
      case "activityType":
        return formatToTitleCase(item.activityType) || "-";

      case "recruiterId":
        return item.recruiterName && item.recruiterId ? (
          <>
            {item.recruiterName}
            <JhText variant="caption2" color="muted-foreground">
              ({item.recruiterId})
            </JhText>
          </>
        ) : (
          item.recruiterName || item.recruiterId || "-"
        );
      case "source":
        return insideSalesLeadSourceObject[item.leadSourceId] === "Repeat lead"
          ? "Renewal lead"
          : insideSalesLeadSourceObject[item.leadSourceId] || "-";
      case "moderator":
        return item.moderatorName || "-";
      case "expiryDate":
        if (item.unallocationDate === null) return "-";
        return customDateFormat(item.unallocationDate);

      case "followUpDate":
        if (item.followupDate === null) return "-";
        return customDateFormat(item.followupDate);

      case "disposition":
        let disposition = dispositionIdMap[item.dispositionId];
        return disposition || "-";
      case "Bucket":
        return insideSalesBucketObject[item.bucketId] || "-";
      default:
        return "-";
    }
  };

  const renderRow = item => (
    <JhTableRow key={item.recruiterId}>
      {headers.map(header => (
        <JhTableCell key={`${item.recruiterId}-${header.key}`} className="p-4">
          {header.key === "remarks" ? (
            item.remarks?.length > 0 ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Info />
                  </TooltipTrigger>
                  <TooltipContent className="bg-slate-100 text-wrap max-w-[200px] break-words border rounded-md">
                    <p className="text-black">{item.remarks}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              "-"
            )
          ) : (
            renderCellContent(item, header.key)
          )}
        </JhTableCell>
      ))}
    </JhTableRow>
  );

  return (
    <JhTable>
      <JhTableHeader>
        <JhTableRow>
          {headers.map(header => (
            <JhTableHead key={header.key} className="p-4 whitespace-nowrap">
              {header.text}
            </JhTableHead>
          ))}
        </JhTableRow>
      </JhTableHeader>
      <JhTableBody>
        {leadsHistory.totalActivitiesCount > 0 ? (
          leadsHistory.activityDetails.map(renderRow)
        ) : (
          <JhTableRow>
            <JhTableCell
              colspan={10}
              align="center"
              className="text-red-500 p-4"
            >
              No Data Found
            </JhTableCell>
          </JhTableRow>
        )}
      </JhTableBody>
    </JhTable>
  );
};

export default LeadHistoryList;
