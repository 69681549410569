import TextEditor from "./TextEditor";
import DOMPurify from "dompurify";

const TextEditorWrapper = ({
  options,
  placeholderContent,
  content,
  onUpdateText
}) => {
  const sanitizeHtml = dirtyHtml => {
    if (!dirtyHtml) return "";

    return DOMPurify.sanitize(dirtyHtml, {
      FORBID_TAGS: ["script", "iframe"]
    });
  };

  const handleUpdate = newContent => {
    var cleanedContent = sanitizeHtml(newContent);
    if (cleanedContent === "<p></p>") cleanedContent = "";
    onUpdateText(cleanedContent);
  };

  return (
    <TextEditor
      options={options}
      placeholderContent={placeholderContent}
      content={content}
      onUpdateText={handleUpdate}
    />
  );
};

export default TextEditorWrapper;
