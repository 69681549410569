import { create } from "zustand";
import { getLeadsHistory } from "../services/leadsHistory";
import moment from "moment";
import { getDateRangeFromToday } from "../services/utilsV2";

const states = {
  PENDING: "PENDING",
  IDLE: "IDLE",
  FETCHED: "FETCHED",
  ERROR: "ERROR"
};

const initialFilters = {
  activityType: "",
  recruiterIds: [],
  leadSourceIds: [],
  dispositionIds: [],
  moderatorIds: [],
  bucketIds: [],
  activityDate: getDateRangeFromToday(7),
  unallocationDate: { from: "", to: "" },
  followUpDate: { from: "", to: "" }
};

const defaultFilters = {
  activityType: "",
  recruiterIds: [],
  leadSourceIds: [],
  dispositionIds: [],
  moderatorIds: [],
  bucketIds: [],
  activityDate: {},
  unallocationDate: { from: "", to: "" },
  followUpDate: { from: "", to: "" }
};

const defaultBucketIds = ["ALL"];

const pageSize = 20;

const useLeadsHistoryStore = create((set, get) => ({
  defaultFilters,
  defaultBucketIds,
  defaultPageSize: pageSize,
  payloadData: {
    page: 1,
    size: pageSize,
    type: "",
    id: "",
    filters: {
      activityDate: {
        to: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        from: moment()
          .subtract(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      }
    }
  },
  initialFilters: { ...initialFilters },
  fetchState: states.PENDING,
  leadsHistoryData: {},
  errorMessage: null,

  fetchLeadsHistoryData: async ({ selectedTab, id }) => {
    set({ fetchState: "PENDING" });
    const { payloadData } = get();
    const newPayload = {
      ...payloadData,
      type: selectedTab,
      id
    };
    set(state => ({
      payloadData: newPayload
    }));

    try {
      const requests = getLeadsHistory(newPayload);

      const responses = await requests;

      set(state => ({
        leadsHistoryData: responses,
        fetchState: "FETCHED",
        errorMesssage: null
      }));
    } catch (error) {
      set({
        fetchState: "ERROR",
        errorMessage: error.message || "error fetching data",
        leadsHistoryData: {}
      });
    }
  },

  updateSelectedTab: tab => {
    set({
      selectedTab: tab
    });
  },

  updateLeadsHistoryFilterData: params => {
    const { newFilter } = params;

    set(state => ({
      payloadData: {
        size: pageSize,
        page: 1,
        filters: {
          ...state.payloadData.filters,
          ...newFilter
        }
      }
    }));
  },

  resetPageNumber: () => {
    set(state => ({
      payloadData: {
        ...state.payloadData,
        page: 1
      }
    }));
  },

  updatePageNumber: ({ page, type, id }) => {
    const { payloadData } = get();
    const newPayload = {
      ...payloadData,
      type: type,
      id,
      page
    };
    set(state => ({
      payloadData: newPayload
    }));

    get().fetchLeadsHistoryData({
      selectedTab: type,
      id
    });
  },

  resetFilterData: ({ selectedTab, id }) => {
    set(state => ({
      payloadData: {
        ...state.payloadData,
        filters: {}
      }
    }));

    get().fetchLeadsHistoryData({
      selectedTab,
      id
    });
  },

  updateInitialFilters: ({ newFilters, selectedTab, id }) => {
    const { payloadData } = get();

    const newpayload = {
      ...payloadData,
      filters: {
        ...payloadData.filters,
        ...newFilters
      }
    };

    set(state => ({
      payloadData: newpayload
    }));

    set(state => ({
      initialFilters: { ...state.initialFilters, ...newFilters }
    }));
    get().fetchLeadsHistoryData({
      selectedTab,
      id
    });
  }
}));

export default useLeadsHistoryStore;
