import { useState } from "react";
import LeadBucket from "./LeadBucket";
import LeadHeader from "./LeadHeader";
import LeadList from "./LeadList";
import LmfsPagination from "../LmfsPagination";
import useInsideLeadStore from "../../../zustandService/useInsideLeadStore";
import LeadFilters from "./LeadFilters";
import TabBox from "./TabBox";
import MarkCallToast from "../MarkCallToast";
import { customUseEffect } from "../../../services/utils";

const TableBoxHeader = ({ handleLeadType, activeLeadType }) => (
  <div className="flex items-center justify-between p-3 mb-4">
    <TabBox handleLeadType={handleLeadType} activeLeadType={activeLeadType} />
    <LeadFilters />
  </div>
);

function getTotalLeadsCount(activeBucket, defaultLeadType, bucketCountData) {
  const { bucketCounts = [], allBucketData = {} } = bucketCountData || {};
  const getCount = (data, type) => {
    if (type === "fresh") return data.freshLeadCount;
    if (type === "renewal") return data.renewLeadCount;
    return data.totalBucketCount;
  };

  if (activeBucket === "ALL") {
    return getCount(allBucketData, defaultLeadType);
  } else {
    const count = bucketCounts.find(item => item.id === activeBucket);
    return count ? getCount(count, defaultLeadType) : 0;
  }
}

const Leads = () => {
  const [isLocalFilter, setLocalFilter] = useState(false);

  const {
    updateLeadsFilterData,
    payloadData,
    updatePageNumber,
    fetchState,
    errorMessage,
    leadData,
    bucketCountData,
    fetchModeratorData,
    defaultLeadType,
    setLeadType,
    activeBucket
  } = useInsideLeadStore(state => ({
    updateLeadsFilterData: state.updateLeadsFilterData,
    payloadData: state.payloadData,
    updatePageNumber: state.updatePageNumber,
    fetchState: state.fetchState,
    errorMessage: state.errorMessage,
    leadData: state.leadData,
    bucketCountData: state.bucketCountData,
    fetchModeratorData: state.fetchModeratorData,
    defaultLeadType: state.defaultLeadType,
    setLeadType: state.setLeadType,
    activeBucket: state.activeBucket
  }));
  const { filters = {}, page = 1, size = 20 } = payloadData;

  customUseEffect(() => {
    setLeadType("ALL");
    delete filters.leadType;
    updateLeadsFilterData({ ...filters }, { resetCountApi: true });
    fetchModeratorData();
    setLocalFilter(false);
  }, []);

  const handleBucket = bucketId => {
    setLeadType("ALL");
    if (bucketId === "ALL") {
      updateLeadsFilterData(
        { bucketIds: [bucketId], leadType: "ALL" },
        { resetCountApi: true }
      );
    } else {
      updateLeadsFilterData({ bucketIds: [bucketId], leadType: "ALL" });
    }
  };

  const handleLeadType = leadType => {
    const count = getTotalLeadsCount(activeBucket, "ALL", bucketCountData);
    if (count > size) {
      updateLeadsFilterData({ leadType });
    } else {
      setLocalFilter(true);
    }
    setLeadType(leadType);
  };

  const handlePagination = page => {
    updatePageNumber(page);
  };

  const segregateLeads = leadsArray => {
    const freshLeads = [];
    const renewalLeads = [];

    leadsArray.forEach(lead => {
      if (lead.leadType === "fresh") {
        freshLeads.push(lead);
      } else if (lead.leadType === "renewal") {
        renewalLeads.push(lead);
      }
    });
    return {
      fresh: freshLeads,
      renewal: renewalLeads,
      ALL: leadsArray
    };
  };

  const { leadsInfo = [] } = leadData || {};
  const segregatedLeads = segregateLeads(leadsInfo);
  let totalLeads = getTotalLeadsCount(
    activeBucket,
    defaultLeadType,
    bucketCountData
  );
  const leadList = isLocalFilter ? segregatedLeads[defaultLeadType] : leadsInfo;
  return (
    <div className="w-screen lg:w-[calc(100vw-15rem)] h-full overflow-scroll pb-12">
      <LeadHeader />
      <LeadBucket
        bucketCountData={bucketCountData}
        handleBucket={handleBucket}
      />
      <div className="py-6 m-3 overflow-x-auto border rounded-xl">
        <TableBoxHeader
          handleLeadType={handleLeadType}
          activeLeadType={defaultLeadType}
        />
        {fetchState === "LOADING" ? (
          <p className="text-center">Loading...</p>
        ) : fetchState === "ERROR" ? (
          <p className="text-red-500 ml-4">
            {errorMessage || "Error fetching data"}
          </p>
        ) : (
          <>
            <LeadList leads={leadList} />
            {!!totalLeads && (
              <LmfsPagination
                totalCount={totalLeads}
                handlePagination={handlePagination}
                activePage={page}
                itemsPerPage={20}
              />
            )}
          </>
        )}
      </div>
      <MarkCallToast />
    </div>
  );
};

export default Leads;
