import { useEffect, useState } from "react";
import { FilterIcon } from "lucide-react";
import DatePicker from "react-datepicker";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../ui/sheet";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import useMetadataStore from "../../../zustandService/useMetadataStore";

import { getFormattedValue } from "../../../services/utils";
import {
  formatDateTime,
  hasAnyValue,
  parseDateStringToDate,
  validateDateRange
} from "../../../services/utilsV2";
import AssignedTo from "../AssignedTo";
import CustomSelect from "../../ui/CustomSelect";
import useLeadsHistoryStore from "../../../zustandService/useLeadsHistoryStore";
import {
  dispositionIdsList,
  allowedActivityType
} from "../../../constants/MetaData";

const LeadHistoryFilters = ({ selectedTab, orgId, recId }) => {
  const [fieldItems, setFieldItems] = useState({});
  const [errorMesage, setErrorMessage] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const { insideSalesLeadSources, insideSalesBuckets } = useMetadataStore(
    state => ({
      insideSalesLeadSources: state.insideSalesLeadSources,
      insideSalesBuckets: state.insideSalesBuckets
    })
  );

  const {
    updateLeadsHistoryFilterData,
    initialFilters,
    defaultFilters,
    updateInitialFilters,
    resetFilterData
  } = useLeadsHistoryStore(state => ({
    updateLeadsHistoryFilterData: state.updateLeadsHistoryFilterData,
    updateInitialFilters: state.updateInitialFilters,
    initialFilters: state.initialFilters,
    defaultFilters: state.defaultFilters,
    defaultBucketIds: state.defaultBucketIds,
    resetFilterData: state.resetFilterData
  }));

  useEffect(() => {
    if (Object.keys(initialFilters).length > 0) {
      setResetFilter(hasAnyValue(initialFilters, true));
      setFieldItems({ ...initialFilters });
    }
  }, []);

  const resetFieldItems = () => {
    setFieldItems(defaultFilters);
    setErrorMessage("");
  };

  const resetFilters = () => {
    setFieldItems(defaultFilters);
    setResetFilter(false);
    if (selectedTab === "organisation") {
      resetFilterData({ selectedTab, id: orgId });
    } else {
      resetFilterData({ selectedTab, id: recId });
    }
  };

  const handleChange = e => {
    let { name, value } = e.target;
    if (name === "recruiterIds") {
      value = getFormattedValue(value, true);
    }
    setFieldItems(prevItems => ({
      ...prevItems,
      [name]: value
    }));
  };

  const handleDateChange = (field, date) => {
    setFieldItems({
      ...fieldItems,
      [field]: date
    });
  };

  const handleOptionChange = (option, filedName) => {
    setFieldItems({
      ...fieldItems,
      [filedName]: option?.id || ""
    });
  };

  const handleSubmit = () => {
    const filters = {};

    setErrorMessage("");

    const fieldsToMap = [
      { key: "activityType", isArray: false },
      { key: "recruiterIds", isArray: true },
      { key: "leadSourceIds", isArray: true },
      { key: "moderatorIds", isArray: true },
      { key: "bucketIds", isArray: true },
      { key: "dispositionIds", isArray: true }
    ];

    fieldsToMap.forEach(({ key, isArray }) => {
      if (fieldItems[key]) {
        filters[key] = isArray ? [Number(fieldItems[key])] : fieldItems[key];
      }
    });

    const dateFields = [
      { field: "activityDate", label: "Activity date" },
      { field: "unallocationDate", label: "Expiry date" },
      { field: "followUpDate", label: "Follow up date" }
    ];

    for (const { field, label } of dateFields) {
      const dateError = validateDateRange(fieldItems, field, label);
      if (dateError) {
        setErrorMessage(dateError);
        return;
      }
      const { from, to } = fieldItems[field];
      if (from || to) {
        filters[field] = {
          from: formatDateTime(from),
          to: formatDateTime(to, "23:59:59")
        };
      }
    }

    // removing filters values with empty string and array values

    Object.keys(filters).forEach(key => {
      if (
        (Array.isArray(filters[key]) && filters[key].length === 0) ||
        (Array.isArray(filters[key]) && filters[key].includes(0)) ||
        filters[key] === ""
      ) {
        delete filters[key];
      }
    });

    updateLeadsHistoryFilterData({
      newFilter: { ...filters }
    });
    setResetFilter(hasAnyValue(filters, true));

    if (selectedTab === "organisation") {
      updateInitialFilters({
        newFilter: { ...filters },
        selectedTab: selectedTab,
        id: orgId
      });
    } else {
      updateInitialFilters({
        newFilter: { ...filters },
        selectedTab: selectedTab,
        id: recId
      });
    }

    handleClose();
  };

  return (
    <div className="flex items-center gap-3">
      <Sheet>
        <SheetTrigger>
          {resetFilter && (
            <Button
              onClick={resetFilters}
              className="mr-2 bg-blue-500 border border-blue-500 text-white hover:bg-blue-600"
            >
              Reset Filter
            </Button>
          )}
          <Button
            variant="outline"
            className="ml-2"
            size="icon"
            onClick={handleOpen}
          >
            <FilterIcon className="w-4 h-4" />
          </Button>
        </SheetTrigger>
        <SheetContent
          isOpen={isOpen}
          handleClose={handleClose}
          className="overflow-y-auto max-h-full filter"
        >
          <SheetHeader>
            <SheetTitle>Filters</SheetTitle>
          </SheetHeader>
          <div className="flex flex-col gap-4 mt-12">
            <div className="grid gap-2">
              <Label htmlFor="lead-activity">Activity Type</Label>
              <CustomSelect
                options={allowedActivityType}
                value={fieldItems.activityType}
                onChange={handleOptionChange}
                placeholder="Select Activity Type"
                fieldName="activityType"
                labelKey="label"
              />
            </div>
            {selectedTab === "organisation" ? (
              <>
                <div className="grid gap-2">
                  <Label htmlFor="recruiterId" className="text-sm font-medium">
                    Recruiter Id
                  </Label>
                  <Input
                    id="recruiterId"
                    name="recruiterIds"
                    type="tel"
                    placeholder="123456"
                    value={fieldItems.recruiterIds || []}
                    onChange={handleChange}
                    maxLength={20}
                  />
                </div>
              </>
            ) : null}

            <AssignedTo
              fieldItems={fieldItems}
              setFieldItems={setFieldItems}
              label={"Moderator"}
            />
            <div className="grid gap-2">
              <Label htmlFor="source">Lead Source</Label>
              <CustomSelect
                options={insideSalesLeadSources}
                value={fieldItems.leadSourceIds}
                onChange={handleOptionChange}
                placeholder="Select Lead Source"
                fieldName="leadSourceIds"
                labelKey="label"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="source">Disposition</Label>
              <CustomSelect
                options={dispositionIdsList}
                value={fieldItems.dispositionIds}
                onChange={handleOptionChange}
                placeholder="Select Disposition"
                fieldName="dispositionIds"
                labelKey="label"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="bucketIds">Bucket</Label>
              <CustomSelect
                options={insideSalesBuckets}
                value={fieldItems.bucketIds}
                onChange={handleOptionChange}
                placeholder="Select Bucket"
                fieldName="bucketIds"
                labelKey="label"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="endDate">Activity Date</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.activityDate?.from
                  )}
                  onSelect={date =>
                    handleDateChange("activityDate", {
                      ...fieldItems.activityDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.activityDate?.to)}
                  onSelect={date =>
                    handleDateChange("activityDate", {
                      ...fieldItems.activityDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(fieldItems.activityDate?.from)}
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label className="text-sm font-medium">Follow Up Date</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.followUpDate?.from
                  )}
                  onSelect={date =>
                    handleDateChange("followUpDate", {
                      ...fieldItems.followUpDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.followUpDate?.to)}
                  onSelect={date =>
                    handleDateChange("followUpDate", {
                      ...fieldItems.followUpDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(fieldItems.followUpDate?.from)}
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label className="text-sm font-medium">Expiry</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.unallocationDate?.from
                  )}
                  onSelect={date =>
                    handleDateChange("unallocationDate", {
                      ...fieldItems.unallocationDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.unallocationDate?.to
                  )}
                  onSelect={date =>
                    handleDateChange("unallocationDate", {
                      ...fieldItems.unallocationDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30 z-99"
                  minDate={parseDateStringToDate(
                    fieldItems.unallocationDate?.from
                  )}
                />
              </div>
            </div>
          </div>
          {errorMesage && (
            <div className="flex text-red-500 mt-6">{errorMesage}</div>
          )}
          <SheetFooter className="flex justify-end mt-12">
            <Button variant="outline" onClick={resetFieldItems}>
              Reset
            </Button>
            <Button onClick={handleSubmit}>Apply Filter</Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default LeadHistoryFilters;
