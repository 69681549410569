import { isEmpty } from "../../../services/utils";
const YesNoSelect = ({ lable, name, value, onChange }) => {
  return (
    <div>
      {lable}
      <select
        className="selectBox"
        name={name}
        value={!isEmpty(value) ? value : ""}
        onChange={onChange}
      >
        <option value="">Select</option>
        <option value={1}>Yes</option>
        <option value={0}>No</option>
      </select>
    </div>
  );
};
export default YesNoSelect;
