import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../utils";

export const getLeadsHistory = async payloadData => {
  try {
    const response = await JobHai.post(`/lms/v1/lead-history`, payloadData);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
