import { ListPlus } from "lucide-react";
import TextEditorWrapper from "../TextEditor/TextEditorWrapper";

const EnhancedJobDescription = ({ content, onUpdateText }) => {
  return (
    <>
      <div className={`w-full rounded-2xl h-98 border richJdBorder my-6 `}>
        <div className={`gap-4 px-4 py-3 flex border-b richJdBorder `}>
          <ListPlus className="basicH2Text" />
          <h1 className="basic-h2-text font-semibold text-base flex gap-1">
            Job Description
            <p className="text-red-500">*</p>
          </h1>
        </div>
        <div className="p-4 w-full">
          <div
            className={`bg-white border textEditorBorder rounded-2xl max-h-96 w-full`}
          >
            <TextEditorWrapper
              options={{ headers: [], bold: true, italic: true, lists: true }}
              placeholderContent="Start writing your job description here or choose suggested template"
              content={content}
              onUpdateText={onUpdateText}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EnhancedJobDescription;
