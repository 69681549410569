import { create } from "zustand";
import {
  getAssignmentModeratorMapping,
  getModeratorConfig,
  getSalesAssignmentCohort,
  saveAssignmentModeratorMapping,
  saveModeratorConfig,
  saveSalesAssignmentCohort
} from "../services/leadAssignment";
import { compareObjects, isObject } from "../services/utils";

const useLeadAssignmentStore = create((set, get) => ({
  salesCohortdata: [],
  salesCohortFetchState: "LOADING",
  salesCohortHelperText: {
    text: "",
    type: ""
  },
  selectedSalesCohort: {},
  selectedSalesCohortDraft: {},
  moderatorConfigFetchState: "LOADING",
  moderatorConfigHelperText: {
    text: "",
    type: ""
  },
  moderatorConfig: [],
  moderatorLevelColumns: {
    Name: "name",
    "Planned Connects": "current",
    New: "new",
    Total: "total",
    TL: "isTl",
    Team: "team",
    Active: "isActive"
  },
  teamLeads: [],
  moderatorConfigLocalState: [],
  assignmentModeratorMappingData: [],
  assignmentModeratorMappingFetchState: "LOADING",
  assignmentModeratorHelperText: {
    text: "",
    type: ""
  },
  leadAssignmentColumns: {
    "Lead Type": "source",
    Cohort: "cohortLabel",
    "Daily Volume": "dailyVolume",
    "Sales Executive": "moderators"
  },
  salesType: [
    { id: 1, label: "Ecommerce" },
    { id: 2, label: "Direct Sales" },
    { id: 3, label: "Corp Sales" }
  ],
  assignedModerators: {},
  assignedModeratorsDraft: {},
  updateModeratorConfigState: (modId, data) => {
    const state = JSON.parse(JSON.stringify(get().moderatorConfigLocalState));
    const index = state.findIndex(item => item.id == modId);
    state[index] = {
      ...state[index],
      ...data
    };
    set({
      moderatorConfigLocalState: state
    });
  },
  updateSelectedSalesCohort: (cohortId, updatedData, isDraft = true) => {
    const keyName = isDraft
      ? "selectedSalesCohortDraft"
      : "selectedSalesCohort";
    const data = get()[keyName];
    set({
      [keyName]: {
        ...data,
        [cohortId]: {
          ...data[cohortId],
          selectedCohortValues: updatedData
        }
      }
    });
    if (!isDraft) {
      set({
        selectedSalesCohortDraft: get().selectedSalesCohort
      });
    }
  },
  updateSalesCohortApiData: response => {
    let selectedSalesCohort = {};
    response.forEach(item => {
      selectedSalesCohort[item.cohortId] = {
        cohortId: item.cohortId,
        selectedCohortValues: item.selectedCohortValues.map(item => {
          return {
            ...item
          };
        })
      };
    });
    set({
      salesCohortdata: [...response],
      salesCohortFetchState: "SUCCESS",
      selectedSalesCohort,
      selectedSalesCohortDraft: selectedSalesCohort
    });
  },
  fetchSalesAssignmentCohort: async () => {
    try {
      set({
        salesCohortFetchState: "LOADING"
      });
      const response = await getSalesAssignmentCohort();
      get().updateSalesCohortApiData(response);
    } catch (err) {
      set({
        salesCohortFetchState: "ERROR",
        salesCohortdata: []
      });
    }
  },
  saveSalesAssignmentCohort: async () => {
    const updatedSalesCohort = Object.values(get().selectedSalesCohort);
    const prevSalesCohort = get().salesCohortdata;
    const payload = compareObjects({
      baseObj: prevSalesCohort,
      derivedObj: updatedSalesCohort,
      uniqueId: "cohortId",
      comparatorKeys: ["selectedCohortValues"]
    });
    if (payload.length) {
      try {
        set({
          salesCohortFetchState: "LOADING"
        });
        const response = await saveSalesAssignmentCohort(payload);
        set({
          salesCohortHelperText: {
            text: "Data saved successfully",
            type: "SUCCESS"
          }
        });
        get().updateSalesCohortApiData(response);
      } catch (error) {
        set({
          salesCohortFetchState: "ERROR",
          salesCohortdata: [],
          salesCohortHelperText: {
            text: isObject(error) ? "something went wrong" : error,
            type: "ERROR"
          }
        });
      }
      const timer = setTimeout(() => {
        set({
          salesCohortHelperText: {
            text: "",
            type: ""
          }
        });
        clearTimeout(timer);
      }, 3000);
    }
  },
  fetchModeratorConfig: async params => {
    const { tab = "" } = params || {};
    try {
      set({
        moderatorConfigFetchState: "LOADING"
      });
      const response = await getModeratorConfig(tab);
      const teamLeads = response.filter(item => item.isTl);
      const moderatorConfigLocalState = response.map(item => {
        return {
          id: item.id,
          tlId: item.tlId,
          isActive: item.isActive,
          isTl: item.isTl,
          sourceId: item.sourceId,
          cohortId: item.cohortId,
          teamId: item?.teamId || ""
        };
      });

      set({
        moderatorConfig: response,
        moderatorConfigFetchState: "SUCCESS",
        teamLeads,
        moderatorConfigLocalState
      });
    } catch (err) {
      set({
        moderatorConfigFetchState: "ERROR"
      });
    }
  },
  saveModeratorConfig: async params => {
    const { tab = "" } = params || {};
    const updatedModeratorConfig = get().moderatorConfigLocalState;
    const prevSalesCohort = get().moderatorConfig;
    const filteredPayload = compareObjects({
      baseObj: prevSalesCohort,
      derivedObj: updatedModeratorConfig,
      comparatorKeys: ["isActive", "tlId", "isTl", "teamId"]
    });
    if (filteredPayload.length) {
      try {
        set({
          moderatorConfigFetchState: "LOADING"
        });
        await saveModeratorConfig({
          tab: tab,
          data: filteredPayload
        });
        set({
          moderatorConfigHelperText: {
            text: "Data saved successfully",
            type: "SUCCESS"
          }
        });
      } catch (error) {
        set({
          moderatorConfigHelperText: {
            text: isObject(error) ? "something went wrong" : error,
            type: "ERROR"
          }
        });
      }
      const timer = setTimeout(() => {
        set({
          moderatorConfigHelperText: {
            text: "",
            type: ""
          }
        });
        clearTimeout(timer);
      }, 3000);
      await get().fetchModeratorConfig();
    }
  },
  updateModeratorsAssignmentApiData: response => {
    let assignedModerators = {};
    const updatedResponse = response.map(item => {
      return {
        ...item,
        sourceXcohort: `${item.sourceId}_${item.cohortId}`
      };
    });
    updatedResponse.forEach(item => {
      assignedModerators[item.sourceXcohort] = {
        cohortId: item.cohortId,
        sourceXcohort: item.sourceXcohort,
        sourceId: item.sourceId,
        moderators: item.moderators.map(item => {
          return {
            ...item
          };
        })
      };
    });
    set({
      assignmentModeratorMappingData: updatedResponse,
      assignmentModeratorMappingFetchState: "SUCCESS",
      assignedModerators,
      assignedModeratorsDraft: assignedModerators
    });
  },
  fetchAssignmentModeratorMapping: async () => {
    try {
      set({ assignmentModeratorMappingFetchState: "LOADING" });
      const response = await getAssignmentModeratorMapping();
      get().updateModeratorsAssignmentApiData(response);
    } catch (err) {
      set({
        assignmentModeratorMappingFetchState: "ERROR",
        assignmentModeratorMappingData: []
      });
    }
  },
  updateAssignedModeratorsState: (cohortId, updatedData, isDraft = true) => {
    const keyName = isDraft ? "assignedModeratorsDraft" : "assignedModerators";
    const data = get()[keyName];
    set({
      [keyName]: {
        ...data,
        [cohortId]: {
          ...data[cohortId],
          moderators: updatedData
        }
      }
    });
    if (!isDraft) {
      set({
        assignedModeratorsDraft: get().assignedModerators
      });
    }
  },
  saveModeratorAssignment: async () => {
    const updatedModeratorAssignment = Object.values(get().assignedModerators);
    const prevModeratorAssignment = get().assignmentModeratorMappingData;
    const filteredPayload = compareObjects({
      baseObj: prevModeratorAssignment,
      derivedObj: updatedModeratorAssignment,
      comparatorKeys: ["moderators"],
      uniqueId: "sourceXcohort"
    });
    const result = filteredPayload.flatMap(item =>
      item.moderators.map(moderator => ({
        moderatorId: moderator.id,
        sourceId: item.sourceId,
        cohortId: item.cohortId,
        isSelected: moderator.isSelected
      }))
    );
    if (result.length > 0) {
      try {
        set({
          assignmentModeratorMappingFetchState: "LOADING"
        });
        await saveAssignmentModeratorMapping(result);
        set({
          assignmentModeratorHelperText: {
            text: "Data saved successfully",
            type: "SUCCESS"
          }
        });
      } catch (error) {
        set({
          assignmentModeratorHelperText: {
            text: isObject(error) ? "something went wrong" : error,
            type: "ERROR"
          }
        });
      }
      const timer = setTimeout(() => {
        set({
          assignmentModeratorHelperText: {
            text: "",
            type: ""
          }
        });
        clearTimeout(timer);
      }, 3000);
      await get().fetchAssignmentModeratorMapping();
    }
  }
}));

export default useLeadAssignmentStore;
