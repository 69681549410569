import React from "react";
import { isAdmin } from "../../services/utilsV2";
import useInsideLeadStore from "../../zustandService/useInsideLeadStore";
import AssignedTo from "./AssignedTo";

// type TeamLeadOptionProps = {
//   fieldItems: Object,
//   setFieldItems: React.Dispatch<React.SetStateAction<{}>>,
//   resetKey: number,
//   label?: string,
//   fieldName?: string
// };

// const TeamLeadOption: React.FC<TeamLeadOptionProps> = ({
const TeamLeadOption = ({
  fieldItems,
  setFieldItems,
  resetKey,
  label = "Team Lead",
  fieldName = "teamLeadIds"
}) => {
  const { moderatorList } = useInsideLeadStore(state => ({
    moderatorList: state.moderatorList
  }));

  let teamLeadList = [];
  if (isAdmin()) {
    teamLeadList = moderatorList.filter(
      item => item.lmsUserLevel === "TEAM_LEAD"
    );
  }

  return teamLeadList.length ? (
    <div className="grid gap-2">
      <AssignedTo
        fieldItems={fieldItems}
        setFieldItems={setFieldItems}
        label={label}
        fieldName={fieldName}
        optionsList={teamLeadList}
        resetKey={resetKey}
      />
    </div>
  ) : null;
};
export default TeamLeadOption;
