export const fieldsToMap = [
  { key: "companyId", isArray: true },
  { key: "companyName", isArray: false },
  { key: "recruiterIds", isArray: true },
  { key: "recruiterName", isArray: false },
  { key: "recruiterPhone", isArray: true },
  { key: "leadSourceIds", isArray: true },
  { key: "moderatorIds", isArray: true },
  { key: "salesChannel", isArray: false },
  { key: "teamLeadIds", isArray: true }
];

export const dateFields = [
  { field: "assignedDate", label: "Assigned date" },
  { field: "followupDate", label: "Follow up date" },
  { field: "churnDate", label: "Churn date" },
  { field: "saleDate", label: "Sale date" },
  { field: "unallocationDate", label: "Expiry" }
];

export const minMaxFields = [
  { field: "jobCount", label: "Job Posted" },
  { field: "creditsPurchased", label: "Credits purchased" },
  { field: "creditsAvailable", label: "Credits available" }
];

export const dataRange = [
  { id: 1, label: "Today" },
  { id: 2, label: "Last 2 days" },
  { id: 3, label: "Last 7 days" }
];
