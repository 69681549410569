import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import withRouter from "../../services/withRouter";
import PremiumTag from "../Common/PremiumTag";
import MetaData from "../../constants/MetaData";
import {
  getErrorMessage,
  getEditPageURL,
  getFormattedValue
} from "../../services/utils";
import {
  RECRUITER_JS_DETAILS,
  RECUITERS_LIST
} from "../../routes/RouteConstants";
import HeadingRow from "../Common/HeadingRow";
import ApplicationsCountSummery from "./ApplicationsCountSummery";
import CustomePagination from "../Common/Pagination";

const Applications = ({ params = {} }) => {
  const pageSize = 20;
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get("type");
  const [applicantList, setApplicantList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [msgData, setMsgData] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const recruiterId = params.id || "";
  const [status, setStatus] = useState(type);
  const [jobId, setJobId] = useState("");
  const [is_consultant_job, setIsConsultantJob] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [jobPlan, setJobPlan] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    getRecJobs();
  }, [activePage]);

  const getRecJobs = async () => {
    const page = activePage ? Number(activePage) - 1 : 0;
    let url = `/view-recruiter-jobs?recruiter_id=${Number(
      recruiterId
    )}&page_number=${page}&size=${pageSize}${
      jobId ? `&job_id=${Number(jobId)}` : ""
    }`;
    if (status) url += `&status=${status}`;
    if (is_consultant_job) url += `&is_consultant=${is_consultant_job}`;
    if (jobPlan) url += `&jobPlan=${jobPlan}`;
    try {
      const response = await JobHai.get(url);
      const { jobs = [], totalJobCount = 0 } = response?.data?.data;
      setApplicantList(jobs);
      setTotalCount(totalJobCount);
      setIsLoading(false);
      setIsButtonDisabled(false);
    } catch (error) {
      const msg = getErrorMessage(error);
      setMsgData({ msg, showMsg: true, msgType: "error" });
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "jobId") setJobId(getFormattedValue(value, true, true));
    else if (name === "status") setStatus(value);
    else if (name === "is_consultant_job") setIsConsultantJob(value);
    else if (name === "jobPlan") setJobPlan(value);
  };

  const searchRecJobs = () => {
    getRecJobs();
    setActivePage(1);
    setIsButtonDisabled(true);
  };

  const handleClosePopup = () => {
    setMsgData({ msg: "", showMsg: false });
  };

  const handlePageChange = page => {
    setActivePage(page);
  };

  const columns = [
    {
      text: "Job ID",
      dataField: "job_id",
      formatter: (rowContent, row) => {
        const editUrl = getEditPageURL(
          row.verification_status,
          row.job_id,
          recruiterId,
          "QA"
        );
        return (
          <>
            <a
              href={`${editUrl}&returnURL=applicant`}
              target={"_blank"}
              rel="noreferrer"
            >
              {rowContent}
            </a>
            <PremiumTag jobPlan={row.jobPlan} />
          </>
        );
      }
    },
    { text: "Company Name", dataField: "company_name" },
    { text: "Job Title", dataField: "job_title", sort: true },
    { text: "Role", dataField: "role", sort: true },
    { text: "Status", dataField: "status", sort: true },
    { text: "Openings", dataField: "no_of_openings", sort: true },
    {
      text: "Unique JS (call/apply/reco)",
      dataField: "unique_js",
      sort: true
    },
    {
      text: "Unique Applications (call/ apply)",
      dataField: "unique_applications_count",
      sort: true
    },
    { text: "Unique Reco", dataField: "reco_count", sort: true },
    { text: "Rec Called", dataField: "r_call_count", sort: true },
    { text: "Rec Shortlisted", dataField: "shortlist_count", sort: true },
    { text: "Rec Rejected", dataField: "reject_count", sort: true },
    {
      text: "",
      dataField: "view_js",
      formatter: (rowContent, row) => {
        return (
          <>
            <a
              href={`${RECRUITER_JS_DETAILS}/${row.job_id}`}
              target={"_blank"}
              title="View Job Seeker"
              rel="noreferrer"
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </a>
          </>
        );
      }
    }
  ];

  const statusOptions = [
    { val: "", label: "Select Status" },
    { val: "LIVE", label: "Live" },
    { val: "IN QA", label: "In QA" },
    { val: "PENDING", label: "Pending" },
    { val: "EXPIRED", label: "Expired" },
    { val: "REJECTED", label: "Rejected" },
    { val: "CLOSED", label: "Closed" }
  ];

  const NoDataIndication = () => <div className="spinner">No Data Found</div>;

  if (isLoading) {
    return <LoadingSpinner key="loading" />;
  }
  return (
    <div className="job_seeker">
      <div className="clr gap-20" />
      <PopupMessage data={msgData} click={handleClosePopup} />
      <HeadingRow
        heading="Job Responses (Applications/Recos)"
        goBackURL={RECUITERS_LIST}
      />
      <div className="row">
        <div className="col-md-1">
          <div className="form-group">
            <b className="downArrow" />
            <select
              className="form-control"
              name="status"
              onChange={handleChange}
              value={status}
            >
              {statusOptions.map(option => (
                <option value={option.val} key={option.val}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <b className="downArrow" />
            <select
              className="form-control"
              name="is_consultant_job"
              value={is_consultant_job}
              onChange={handleChange}
            >
              <option value="">Consultant Job</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="jobId"
              placeholder="Job Id"
              value={jobId}
              onChange={handleChange}
              maxLength={10}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <b
              className="downArrow"
              style={{ padding: "3px", marginTop: "13px" }}
            />
            <select
              className="form-control"
              name="jobPlan"
              value={jobPlan}
              onChange={handleChange}
            >
              <option value="">Select Job Type</option>
              {MetaData.premiumJobType.map(option => (
                <option value={option.value} key={`premium${option}`}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-1">
          <div className="float-right">
            <button
              className="btn btn-primary"
              onClick={searchRecJobs}
              disabled={isButtonDisabled}
            >
              SEARCH
            </button>
          </div>
        </div>
        <div className="col-md-5 sortingWrap">
          <ApplicationsCountSummery
            recruiterId={recruiterId}
            handleError={setMsgData}
          />
        </div>
      </div>
      <div className="clr gap-20" />
      <div className="whitebox rec-job-list">
        <BootstrapTable
          key="applicant"
          keyField="serialNo"
          data={applicantList}
          columns={columns}
          hover
          condensed
          bordered={false}
          loading={true}
          rowClasses="listvalue"
          headerClasses="listHeding"
          classes="borderless"
          noDataIndication={() => <NoDataIndication />}
        />
        <CustomePagination
          activePage={activePage}
          itemsCountPerPage={pageSize}
          totalItemsCount={totalCount}
          onPageChange={handlePageChange}
          showPagination={totalCount > applicantList.length}
          showLimitedRow={true}
        />
        <div className="clr gap-20" />
      </div>
    </div>
  );
};

export default withRouter(Applications);
