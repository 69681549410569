import { FlagIcon, ShoppingCartIcon, Headset } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../ui/table";
import {
  customDateFormat,
  formatDateAgo,
  isLmsModerator
} from "../../../services/utilsV2";
import {
  LFMS_LEAD_DETAILS,
  RECUITERS_APPLICANT
} from "../../../routes/RouteConstants";
import useMetadataStore from "../../../zustandService/useMetadataStore";
import useInsideLeadStore from "../../../zustandService/useInsideLeadStore";
import moment from "moment/moment";

let headers = [
  { text: "Rec Id", key: "recruiterId" },
  { text: "Name", key: "name" },
  { text: "Company", key: "companyName" },
  { text: "Bucket", key: "bucketId" },
  { text: "Date", key: "date" },
  { text: "Jobs", key: "jobCount" },
  { text: "Purchased", key: "creditsPurchased" },
  { text: "Available", key: "creditsAvailable" },
  { text: "Source", key: "leadSourceId" },
  { text: "Lead Activity", key: "lastPurchaseAttemptDate" },
  { text: "Expiring", key: "unallocationDate" }
];

if (!isLmsModerator()) {
  headers = [...headers, { text: "Executive", key: "moderatorName" }];
}

const dateMappings = {
  "New Lead": "assignmentDate",
  "Sales done": "saleDate",
  "Churn Lead": "churnDate",
  Restricted: "restrictedDate",
  "Hot Lead": "followupDate",
  "Warm Lead": "followupDate",
  "Follow-up": "followupDate"
};

const dateHeadingMapping = {
  assignmentDate: "Assignment Date",
  saleDate: "Sale Date",
  churnDate: "Churn Date",
  restrictedDate: "Restricted Date",
  followupDate: "Follow up Date"
};

const LeadList = ({ leads = [] }) => {
  const { insideSalesBucketObject, insideSalesLeadSourceObject } =
    useMetadataStore(state => ({
      insideSalesBucketObject: state.insideSalesBucketObject,
      insideSalesLeadSourceObject: state.insideSalesLeadSourceObject
    }));

  const { activeBucket } = useInsideLeadStore(state => ({
    activeBucket: state.activeBucket
  }));

  const bucketLabel = insideSalesBucketObject[activeBucket] || "All";
  const dateKey = dateMappings[bucketLabel] || "followupDate";

  const getDateColumns = item => {
    return item[dateKey]
      ? moment(item[dateKey]).format("D MMMM hh:mm A")
      : "NA";
  };

  const getLastActivity = item => {
    // 1=>Contact sales ; 3=>Payment drop off ; 4=>Check out page abandoned
    // Lead Source
    const paymentSourceIds = [1, 3, 4];
    const { lastPurchaseAttemptDate, lastAttemptedSourceId, leadSourceId } =
      item;

    if (
      lastPurchaseAttemptDate &&
      (paymentSourceIds.includes(leadSourceId) ||
        paymentSourceIds.includes(lastAttemptedSourceId))
    ) {
      const latestSourceId = lastAttemptedSourceId || leadSourceId;
      const icon =
        latestSourceId === 1 ? (
          <Headset size={16} className="w-[24px] text-blue-500" />
        ) : latestSourceId === 3 ? (
          <FlagIcon size={16} className="w-[24px] text-blue-500" />
        ) : (
          <ShoppingCartIcon size={16} className="w-[24px] text-blue-500" />
        );
      return (
        <div className="flex items-center w-[124px]">
          {icon} {formatDateAgo(lastPurchaseAttemptDate)}
        </div>
      );
    }
    return "NA";
  };
  const renderCellContent = (item, headerKey) => {
    switch (headerKey) {
      case "recruiterId":
        return item?.isSalesAttributed ? (
          <>{item.recruiterId}*</>
        ) : (
          item.recruiterId
        );
      case "name":
        return item.name;
      case "companyName":
        return item.companyName;
      case "bucketId":
        return insideSalesBucketObject[item.bucketId] || "NA";
      case "date":
        return getDateColumns(item);
      case "jobCount":
        return item.jobCount > 0 ? (
          <Link
            to={`${RECUITERS_APPLICANT}/${item.recruiterId}`}
            onClick={e => e.stopPropagation()}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700"
          >
            {item.jobCount}
          </Link>
        ) : (
          item.jobCount
        );
      case "creditsPurchased":
        return item.creditsPurchased;
      case "creditsAvailable":
        return item.creditsAvailable;
      case "leadSourceId":
        return insideSalesLeadSourceObject[item.leadSourceId] === "Repeat lead"
          ? "Renewal lead"
          : insideSalesLeadSourceObject[item.leadSourceId] || "NA";
      case "lastPurchaseAttemptDate":
        return getLastActivity(item);
      case "unallocationDate":
        return customDateFormat(item.unallocationDate, "D MMMM");
      case "moderatorName":
        return item.moderatorName;
      default:
        return "";
    }
  };
  const navigate = useNavigate();
  const handleClick = item => {
    navigate(
      `${LFMS_LEAD_DETAILS}?leadId=${item.leadId}&recruiterId=${item.recruiterId}&bucketId=${item.bucketId}`
    );
  };

  const renderRow = item => (
    <TableRow
      key={item.recruiterId} // Use a unique key based on your data
      onClick={() => handleClick(item)}
      className="cursor-pointer"
    >
      {headers.map(header => (
        <TableCell key={`${item.recruiterId}-${header.key}`}>
          {renderCellContent(item, header.key)}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          {headers.map(header => (
            <TableHead key={header.key}>
              <div className="flex items-center space-x-3">
                {header.key === "date"
                  ? dateHeadingMapping[dateKey]
                  : header.text}
              </div>
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {leads.length ? (
          leads.map(renderRow)
        ) : (
          <TableRow>
            <TableCell colspan={13} align="center" className="text-red-500">
              No Data Found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default LeadList;
