import { JhText } from "../../Tailwind/design-packages/textPackage";
import { Info } from "lucide-react";

const InvalidJob = ({ message }) => {
  return (
    <div className="containerCard min-h-max">
      <div className="flex items-center destructive p-8 group bg-rose-50 text-rose-500">
        <Info className="h-4 w-4 mr-2" />
        <JhText variant="subtitle3" color="destructive">
          {message}
        </JhText>
      </div>
      <div className="errorMsg"></div>
    </div>
  );
};
export default InvalidJob;
