export const fieldsToMap = [
  { key: "orgName", isArray: false },
  { key: "orgIds", isArray: true },
  { key: "orderIds", isArray: true },
  { key: "recruiterIds", isArray: true },
  { key: "recruiterName", isArray: false },
  { key: "recruiterPhone", isArray: false },
  { key: "moderatorIds", isArray: true },
  { key: "teamLeadIds", isArray: true },
  { key: "salesChannel", isArray: false },
  { key: "salesAttributionCount", isArray: false }
];

export const minMaxFields = [
  { field: "creditsPurchased", label: "Credits purchased" }
];

export const dateFields = [
  { field: "creditPurchaseDate", label: "Credit PurchaseDate Date" }
];
