import { useState } from "react";
import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../../services/utils";

const ApplicationsCountSummery = ({ recruiterId, handleError }) => {
  const [summaryData, setSummaryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const fetchSummary = async () => {
    try {
      setIsLoading(true);
      const response = await JobHai.get(
        `/recruiter-jobs-status-summary?recruiter_id=${recruiterId}`
      );
      setSummaryData(response.data?.data);
      setShowSummary(true);
    } catch (error) {
      const msg = getErrorMessage(error);
      handleError({ msg, showMsg: true, msgType: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  if (!showSummary) {
    return (
      <div className="row">
        <div className="col-md-12">
          <button
            className="btn btn-primary"
            onClick={fetchSummary}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "View Summary"}
          </button>
        </div>
      </div>
    );
  }

  const { BASIC, PREMIUM } = summaryData || {};

  // Calculate total counts by summing all status counts
  const calculateTotal = data => {
    if (!data) return 0;
    return (
      (data.LIVE || 0) +
      (data.PENDING || 0) +
      (data.IN_QA || 0) +
      (data.EXPIRED || 0) +
      (data.REJECTED || 0)
    );
  };

  const basicTotal = calculateTotal(BASIC);
  const premiumTotal = calculateTotal(PREMIUM);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <span>
            <b />
            BASIC ({basicTotal})
          </span>
          <span>
            <b className="green" />
            LIVE ({BASIC?.LIVE || 0})
          </span>
          <span>
            <b className="blue" />
            PENDING ({BASIC?.PENDING || 0})
          </span>
          <span>
            <b className="yellow" />
            IN QA Jobs ({BASIC?.IN_QA || 0})
          </span>
          <span>
            <b style={{ backgroundColor: "#3405d9" }} />
            EXPIRED ({BASIC?.EXPIRED || 0})
          </span>
          <span>
            <b className="red" />
            REJECTED ({BASIC?.REJECTED || 0})
          </span>
          <span>
            <b className="red" />
            CLOSED ({BASIC?.CLOSED || 0})
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <span>
            <b />
            PREMIUM ({premiumTotal})
          </span>
          <span>
            <b className="green" />
            LIVE ({PREMIUM?.LIVE || 0})
          </span>
          <span>
            <b className="blue" />
            PENDING ({PREMIUM?.PENDING || 0})
          </span>
          <span>
            <b className="yellow" />
            IN QA Jobs ({PREMIUM?.IN_QA || 0})
          </span>
          <span>
            <b style={{ backgroundColor: "#3405d9" }} />
            EXPIRED ({PREMIUM?.EXPIRED || 0})
          </span>
          <span>
            <b className="red" />
            REJECTED ({PREMIUM?.REJECTED || 0})
          </span>
          <span>
            <b className="red" />
            CLOSED ({PREMIUM?.CLOSED || 0})
          </span>
        </div>
      </div>
    </>
  );
};

export default ApplicationsCountSummery;
