import { useState } from "react";
import {
  Tabs,
  TabsList,
  TabsContent,
  TabsTrigger
} from "@jobhai-component-library/jh-tabs";
import { Card } from "../../ui/card";
import { JhText } from "../../../Tailwind/design-packages/textPackage";
import { Text } from "@jobhai-component-library/jh-text";
import StateRenderer from "./stateRenderer";
import LmfsPagination from "../LmfsPagination";
import LeadHistoryList from "./leadsHistoryList";
import useLeadsHistoryStore from "../../../zustandService/useLeadsHistoryStore";
import LeadHistoryFilters from "./leadHistoryFilter";

let LeadsHistory = ({ orgId, recId }) => {
  const {
    defaultPageSize,
    payloadData,
    updatePageNumber,
    fetchState,
    errorMessage,
    leadsHistoryData,
    fetchLeadsHistoryData,
    resetPageNumber
  } = useLeadsHistoryStore(state => ({
    defaultPageSize: state.defaultPageSize,
    fetchLeadsHistoryData: state.fetchLeadsHistoryData,
    updateLeadsHistoryFilterData: state.updateLeadsHistoryFilterData,
    payloadData: state.payloadData,
    updatePageNumber: state.updatePageNumber,
    fetchState: state.fetchState,
    errorMessage: state.errorMessage,
    leadsHistoryData: state.leadsHistoryData,
    resetFilterData: state.resetFilterData,
    selectedTab: state.selectedTab,
    updateSelectedTab: state.updateSelectedTab,
    resetPageNumber: state.resetPageNumber
  }));

  const [page, setPage] = useState(payloadData.page || 1);
  const [selectedTab, setSelectedTab] = useState(null);

  const handlePagination = page => {
    setPage(page);
    if (selectedTab === "organisation") {
      updatePageNumber({ page: page, type: selectedTab, id: orgId });
    } else {
      updatePageNumber({ page: page, type: selectedTab, id: recId });
    }
  };

  var title = "Leads History (Select Company/Recruiter to view history)";
  if (selectedTab) {
    selectedTab === "organisation"
      ? (title = " Leads History - Company")
      : (title = "Leads History - Recruiter");
  }

  const handleTabChange = tabSelected => {
    setSelectedTab(tabSelected);
    resetPageNumber();
    setPage(1);

    tabSelected === "organisation"
      ? fetchLeadsHistoryData({ selectedTab: tabSelected, id: orgId })
      : fetchLeadsHistoryData({ selectedTab: tabSelected, id: recId });
  };

  return (
    <>
      {
        <div className="flex items-center justify-between p-3 mb-4 ">
          <h1 className="mb-2 text-xl font-semibold mt-4 ">{title}</h1>
          <LeadHistoryFilters
            selectedTab={selectedTab}
            orgId={orgId}
            recId={recId}
          />
        </div>
      }
      <Tabs
        onValueChange={tabSelected => handleTabChange(tabSelected)}
        value={selectedTab}
      >
        <TabsList className="grid grid-cols-2">
          <TabsTrigger value="organisation">Company</TabsTrigger>
          <TabsTrigger value="recruiter">Recruiter</TabsTrigger>
        </TabsList>
      </Tabs>
      {selectedTab ? (
        <>
          {fetchState === "FETCHED" ? (
            <StateRenderer state={fetchState}>
              <div className="my-4 overflow-x-auto border rounded-xl">
                {fetchState === "FETCHED" ? (
                  <>
                    <LeadHistoryList
                      leadsHistory={leadsHistoryData}
                      selectedTab={selectedTab}
                    />
                    {leadsHistoryData?.totalActivitiesCount >
                      defaultPageSize && (
                      <LmfsPagination
                        totalCount={leadsHistoryData.totalActivitiesCount}
                        handlePagination={handlePagination}
                        activePage={page}
                        itemsPerPage={defaultPageSize}
                      />
                    )}
                  </>
                ) : null}
              </div>
            </StateRenderer>
          ) : null}

          {fetchState === "ERROR" ? (
            <h1 className="text-red-500 ml-4 ">
              {errorMessage || "Error FETCHED data"}
            </h1>
          ) : fetchState === "PENDING" ? (
            <h1 className=" ml-4">Loading ...</h1>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default LeadsHistory;
