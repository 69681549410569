import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { getCompanies } from "../services/insideSales";

const defaultFilters = {
  orgName: "",
  orgId: "",
  recruiterPhone: "",
  recruiterIds: "",
  creditsPurchased: {
    min: "",
    max: ""
  },
  creditsAvailable: {
    min: "",
    max: ""
  },
  unallocationDate: {
    from: "",
    to: ""
  },
  assignedDate: {
    from: "",
    to: ""
  },
  moderatorIds: "",
  verifiedRecruitersCount: {
    min: "",
    max: ""
  },
  cleaningStatus: "",
  teamLeadIds: "",
  salesChannel: ""
};
const pageSize = 20;

const useInsideCompaniesStore = create(
  persist(
    (set, get) => ({
      defaultFilters,
      initialFilters: { ...defaultFilters },
      payloadData: {
        page: 1,
        size: pageSize,
        filters: {}
      },
      fetchState: "LOADING",
      companiesData: {},
      errorMessage: null,

      fetchData: async payloadData => {
        set({ fetchState: "LOADING" });
        try {
          const responses = await getCompanies(payloadData);
          set({
            companiesData: responses,
            fetchState: "SUCCESS",
            errorMessage: null
          });
        } catch (err) {
          console.error("Error fetching data:", err);
          set({
            fetchState: "ERROR",
            companiesData: {},
            errorMessage: err.message || ""
          });
        }
      },

      fetchCompaniesData: () => {
        const { payloadData, fetchData } = get();
        fetchData(payloadData);
      },

      updateFilterData: newFilterData => {
        set(state => ({
          payloadData: {
            ...state.payloadData,
            page: 1,
            filters: { ...newFilterData }
          }
        }));
        const { payloadData, fetchData } = get();
        fetchData(payloadData);
      },

      updatePageNumber: page => {
        set(state => ({
          payloadData: { ...state.payloadData, page }
        }));
        const { payloadData, fetchData } = get();
        fetchData(payloadData);
      },

      resetFilterData: () => {
        set(state => ({
          payloadData: {
            ...state.payloadData,
            filters: {}
          }
        }));
        const { payloadData, fetchData } = get();
        fetchData(payloadData);
      },
      updateInitialFilters: newFilters => {
        set(state => ({
          initialFilters: { ...state.initialFilters, ...newFilters }
        }));
      }
    }),
    {
      name: "lfmsCompaniesData",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

export default useInsideCompaniesStore;
