import { List, PlusIcon, RefreshCcw } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";

const tabList = [
  {
    value: "fresh",
    label: "Never Purchased",
    icon: <PlusIcon size={16} className="mr-2 text-blue-500" />
  },
  {
    value: "renewal",
    label: "Previously Purchased",
    icon: <RefreshCcw size={16} className="mr-2 text-blue-500" />
  },
  {
    value: "ALL",
    label: "All",
    icon: <List size={16} className="mr-2 text-blue-500" />
  }
];
const TabBox = ({ handleLeadType, activeLeadType }) => {
  return (
    <Tabs defaultValue="fresh">
      <TabsList className="rounded-full">
        {tabList.map(tab => (
          <TabsTrigger
            className="rounded-full"
            value={tab.value}
            onClick={() => handleLeadType(tab.value)}
            data-state={activeLeadType === tab.value ? "active" : "inactive"}
            key={`tab${tab.value}`}
          >
            {tab.icon} {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};
export default TabBox;
