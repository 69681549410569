import moment from "moment";
import AddReferral from "./AddReferral";
import ReferralLink from "./ReferralLink";
import {
  capitalizeFirstLetter,
  getDocumentStatus,
  getFieldLabel
} from "../../../services/utils";
import { recruiterStatus } from "../../../constants/MetaData";
import CloseLead from "./CloseLead";
import YesNoSelect from "./YesNoSelect";

const withResetKey = Component => props => {
  const uniqueKey = `${props.recId}-${Date.now()}`;
  return <Component key={uniqueKey} {...props} />;
};
const ResetReferralLink = withResetKey(ReferralLink);

const CrawlStatus = ({
  item,
  handleChange,
  handleSave,
  savedId,
  successMessage,
  errorMsg,
  saveBtnDisabled,
  handlePostJob,
  callBack
}) => {
  const {
    email_status,
    doc_uploaded_date,
    document_status,
    verification_status,
    mobile_verification_status,
    recruiter_consent,
    recruiter_id,
    hiring_domain,
    link_shared,
    recruiter_online_jobs_consent,
    crawling_remarks,
    show_post_job_button,
    is_eligible_for_closure,
    recruiter_sms_consent,
    recruiter_whatsapp_consent,
    paid_offering_consent
  } = item;
  let docStatus = getDocumentStatus(document_status);
  const recStatus = getFieldLabel(
    recruiterStatus,
    verification_status,
    "value",
    "label"
  );
  const onChange = e => {
    handleChange(recruiter_id, e);
  };
  return (
    <div>
      Email Status:{" "}
      <b>{email_status ? capitalizeFirstLetter(email_status) : ""}</b> <br />{" "}
      Doc Status:{" "}
      <b>{docStatus ? capitalizeFirstLetter(docStatus) : docStatus}</b>
      {doc_uploaded_date ? (
        <>
          <br />
          Doc Updated On:
          <b> {moment(doc_uploaded_date).format("YYYY-MM-DD hh:mm:ss")}</b>
        </>
      ) : null}
      <br /> Recruiter Status: <b>{recStatus}</b>
      {mobile_verification_status ? (
        <>
          <br />
          Mobile Verification Status: <b>{mobile_verification_status}</b>
        </>
      ) : null}
      {hiring_domain ? (
        <>
          <br />
          Hiring domain: <b>{hiring_domain}</b>
        </>
      ) : null}
      <YesNoSelect
        lable="Consent for Job Post:"
        name="recruiter_consent"
        value={recruiter_consent}
        onChange={onChange}
      />
      <YesNoSelect
        lable="Link Shared:"
        name="link_shared"
        value={link_shared}
        onChange={onChange}
      />
      <YesNoSelect
        lable="Consent for Online Jobs:"
        name="recruiter_online_jobs_consent"
        value={recruiter_online_jobs_consent}
        onChange={onChange}
      />
      <YesNoSelect
        lable="Consent for WhatsApp:"
        name="recruiter_whatsapp_consent"
        value={recruiter_whatsapp_consent}
        onChange={onChange}
      />
      <YesNoSelect
        lable="Consent for SMS:"
        name="recruiter_sms_consent"
        value={recruiter_sms_consent}
        onChange={onChange}
      />
      <YesNoSelect
        lable="Interested for Paid offerings:"
        name="paid_offering_consent"
        value={paid_offering_consent}
        onChange={onChange}
      />
      <textarea
        className="form-control"
        name="crawling_remarks"
        maxLength="1000"
        rows={1}
        placeholder="Crawling Remarks"
        onChange={e => handleChange(recruiter_id, e)}
        value={crawling_remarks ? crawling_remarks : ""}
      />
      <p>
        {savedId === recruiter_id ? (
          <>
            {successMessage ? (
              <span className="text-success">{successMessage}</span>
            ) : null}
            {errorMsg ? <span className="text-danger">{errorMsg}</span> : null}
          </>
        ) : null}
        <button
          className="btn btn-primary postjob"
          onClick={() => handleSave(item)}
          disabled={saveBtnDisabled && savedId === recruiter_id}
        >
          Save
        </button>
        {show_post_job_button ? (
          <button
            className="btn btn-primary postjob"
            onClick={() => handlePostJob(recruiter_id)}
          >
            Post Job
          </button>
        ) : (
          <>
            {recruiter_consent !== 0 ? (
              <button className="btn btn-secondary postjob" disabled>
                Post Job
              </button>
            ) : null}
          </>
        )}
      </p>
      <ResetReferralLink recId={recruiter_id} />
      <AddReferral recId={recruiter_id} />
      {is_eligible_for_closure ? (
        <CloseLead recId={recruiter_id} callBack={callBack} />
      ) : null}
    </div>
  );
};
export default CrawlStatus;
