import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import cx from "classnames";
import { checkPermission, getErrorMessage } from "../../services/utils";
import { saveJobMiniBoost, styles } from "./helper";
import BoostLogs from "./BoostLogs";
import { BOOST_JOB_CREDITS } from "../../services/constant";

const STATES = {
  PRELIMINARY: "PRELIMINARY",
  CONFIRMATION: "CONFIRMATION",
  IN_PROGRESS: "IN_PROGRESS",
  ERROR: "ERROR"
};

function JobMiniBoost({ classes, jobId, miniBoostDetails = {} }) {
  const [currentState, setCurrentState] = useState(STATES.PRELIMINARY);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    current_mini_boost_count,
    next_boost_status,
    status,
    total_applies = null,
    current_mini_boost_count_via_crm = null,
    mini_boost_credits_worth_done_by_crm = null
  } = miniBoostDetails;

  async function onConfirmYes(st) {
    setErrorMessage("");

    const data = {
      jobId: jobId,
      planType: "miniBoost",
      source: "CRM"
    };
    try {
      await saveJobMiniBoost(data);
      setCurrentState(STATES.IN_PROGRESS);
    } catch (error) {
      const err = getErrorMessage(error);
      setCurrentState(STATES.ERROR);
      setErrorMessage(err);
    }
  }

  function onBoostJob() {
    setCurrentState(STATES.CONFIRMATION);
  }

  let nTimesBoost = current_mini_boost_count;
  if (currentState === STATES.IN_PROGRESS) {
    nTimesBoost = current_mini_boost_count + 1;
  }
  return (
    <div className={classes.widgetRot}>
      <BoostLogs jobId={jobId} />
      {checkPermission("Job_Boost") ? (
        <div className={classes.root}>
          <div className={cx(classes.header, classes.padding)}>
            {currentState === STATES.ERROR ? (
              <>
                {errorMessage && (
                  <div className={classes.errorState}>
                    <i className="fa fa-x" aria-hidden="true" />
                    <span>&nbsp; {errorMessage}</span>
                  </div>
                )}
              </>
            ) : currentState === STATES.IN_PROGRESS ||
              status === "INPROGRESS" ? (
              <div className="flex align-items-center justify-content-between">
                <b>Job Boost currently in progress</b>
                {nTimesBoost ? `${nTimesBoost} time boost` : null}{" "}
              </div>
            ) : currentState === STATES.CONFIRMATION ? (
              <div className="flex align-items-center justify-content-between">
                <div>
                  <b>Boost as an exception</b>
                  <div>
                    Job boost (worth {BOOST_JOB_CREDITS} credits) will be given
                    to the recruiter free of cost
                  </div>
                </div>
                <div>
                  <div className={cx("flex", "buttonContainer", "center")}>
                    <input
                      type="button"
                      onClick={() => onConfirmYes()}
                      value="Confirm​"
                      className={cx(
                        "submitBtn",
                        "btn3",
                        "flex",
                        "justifyCenter"
                      )}
                    />
                  </div>
                </div>
              </div>
            ) : (next_boost_status === "ELIGIBLE" &&
                current_mini_boost_count) ||
              status === "COMPLETED" ? (
              <div className="flex align-items-center justify-content-between">
                <div>
                  <b>
                    Job has been boosted
                    {current_mini_boost_count
                      ? ` ${current_mini_boost_count} times`
                      : null}
                  </b>
                  <div>
                    Total Applies recieved during boost: {total_applies}
                  </div>
                  {current_mini_boost_count_via_crm ? (
                    <div>
                      {current_mini_boost_count_via_crm} Free boosts (worth{" "}
                      {mini_boost_credits_worth_done_by_crm}
                      credits) have been done on this job
                    </div>
                  ) : null}
                </div>
                {checkPermission("Job_Multiple_Boost") &&
                next_boost_status === "ELIGIBLE" ? (
                  <div>
                    <div className={cx("flex", "buttonContainer", "center")}>
                      <input
                        type="button"
                        onClick={() => onBoostJob()}
                        value="Boost Again"
                        className={cx(
                          "submitBtn",
                          "btn3",
                          "flex",
                          "justifyCenter"
                        )}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : currentState === STATES.PRELIMINARY ? (
              <div className="flex align-items-center justify-content-between">
                <div>
                  <b>Job Boost​</b>
                </div>
                <div>
                  <div className={cx("flex", "buttonContainer", "center")}>
                    <input
                      type="button"
                      onClick={() => onBoostJob()}
                      value="Boost this Job​"
                      className={cx(
                        "submitBtn",
                        "btn3",
                        "flex",
                        "justifyCenter"
                      )}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withStyles(styles)(JobMiniBoost);
