export const fieldsToMap = [
  { key: "orgName", isArray: false },
  { key: "orgId", isArray: true },
  { key: "recruiterIds", isArray: true },
  { key: "recruiterPhone", isArray: true },
  { key: "moderatorIds", isArray: true },
  { key: "cleaningStatus", isArray: false },
  { key: "salesChannel", isArray: false },
  { key: "teamLeadIds", isArray: true }
];

export const dateFields = [
  { field: "assignedDate", label: "Assigned date" },
  { field: "unallocationDate", label: "Expiry date" }
];

export const minMaxFields = [
  { field: "creditsAvailable", label: "Credits Available" },
  { field: "creditsPurchased", label: "Credits purchased" },
  { field: "verifiedRecruitersCount", label: "Verified Recruiters" }
];

export const cleaningStatusList = [
  { id: "REVIEWED", name: "Reviewed" },
  { id: "NOT_REVIEWED", name: "Not Reviewed" }
];
