"use client";
import { Editor, EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import {
  Bold,
  Italic,
  List,
  ListOrdered,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6
} from "lucide-react";

const MenuBar = options => {
  const { editor } = useCurrentEditor();
  const { headers, bold, italic, lists } = options.options;

  if (!editor) return null;

  const HeadingButton = ({ level, editor }) => {
    const HeadingIcon = {
      1: Heading1,
      2: Heading2,
      3: Heading3,
      4: Heading4,
      5: Heading5,
      6: Heading6
    }[level];

    return (
      <button
        key={level}
        onClick={() =>
          editor.chain().focus().toggleHeading({ level: level }).run()
        }
      >
        <HeadingIcon className="h-5 w-5" />
      </button>
    );
  };

  const HeadingToolbar = ({ headers = [], editor }) => {
    if (!headers?.length) return null;

    return (
      <>
        {headers.map(level => (
          <HeadingButton key={level} level={level} editor={editor} />
        ))}
      </>
    );
  };

  return (
    <div className="flex items-center border-b h-11 px-5 py-3">
      <div className="flex gap-4 items-center ">
        {bold && (
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={`${
              editor.isActive("bold") ? "text-blue-600 " : "text-gray-900"
            }`}
          >
            <Bold className="h-5 w-5" />
          </button>
        )}
        {italic && (
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={`${
              editor.isActive("italic") ? "text-blue-600 " : "text-gray-900"
            }`}
          >
            <Italic className="h-5 w-5" />
          </button>
        )}
        {lists && <div className="h-3 w-0.5 border mx-2" />}
        {lists && (
          <button
            onClick={() => {
              editor.chain().focus().toggleBulletList().run();
            }}
            className={`${
              editor.isActive("bulletList") ? "text-blue-600 " : "text-gray-900"
            }`}
          >
            <List className="h-5 w-5" />
          </button>
        )}
        {lists && (
          <button
            onClick={() => {
              editor.chain().focus().toggleOrderedList().run();
            }}
            className={`${
              editor.isActive("orderedList")
                ? "text-blue-600 "
                : "text-gray-900"
            }`}
          >
            <ListOrdered className="h-5 w-5" />
          </button>
        )}
        {headers?.length > 0 && <div className="h-3 w-0.5 border mx-2" />}
        {<HeadingToolbar headers={headers} editor={editor} />}
      </div>
    </div>
  );
};

const TextEditor = ({ options, placeholderContent, content, onUpdateText }) => {
  return (
    <EditorProvider
      slotBefore={<MenuBar options={options} />}
      extensions={[
        StarterKit.configure({
          heading: true,
          blockquote: true,
          codeBlock: true,
          strike: true,
          bulletList: true,
          orderedList: true
        }),
        Placeholder.configure({
          placeholder: placeholderContent
        })
      ]}
      content={content}
      onUpdate={({ editor }) => {
        const child = editor.getHTML();
        onUpdateText(child);
      }}
    ></EditorProvider>
  );
};

export default TextEditor;
